export default {
  methods: {
    getFeatureTranslationPath(featureAction) {
      return `page.features_page.section.feature_${featureAction}_section`;
    },
    getFeatureTranslation(featureAction) {
      return this.$tm(this.getFeatureTranslationPath(featureAction));
    },
    getFeatureLearnMorePageName(featureAction) {
      return ["features_single_page", `feature_${featureAction}_page`];
    },
    getFeatureTranslations(featureActions) {
      return featureActions.map((featureAction) => ({
        ...this.getFeatureTranslation(featureAction),
        learnMorePageName: this.getFeatureLearnMorePageName(featureAction),
      }));
    },
  },
};
